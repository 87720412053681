<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Show") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="6" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>
        <b-row
          class="my-date-row mt-1 d-flex justify-content-center align-items-center"
        >
          <b-col cols="4" md="4">
            <label for="">Filtre Başlangıç Tarihi</label>
            <flat-pickr
              v-model="start_date"
              class="form-control"
              :config="configStart"
              placeholder="Başlangıç Tarihi Seçiniz"
            />
          </b-col>
          <b-col cols="4" md="4">
            <label for="">Filtre Bitiş Tarihi</label>
            <flat-pickr
              v-model="end_date"
              class="form-control"
              :config="configEnd"
              placeholder="Bitiş Tarihi Seçiniz"
            />
          </b-col>
          <b-col
            cols="2"
            md="2"
            class="d-flex justify-content-center align-items-center"
          >
            <b-form-checkbox
              class="mt-3"
              id="checkbox-1"
              v-model="checkActive"
              name="checkbox-1"
              value="checked"
            >
              <p>
                <b class="text-primary">{{ $t("Aktif Randevular") }}</b>
              </p>
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="2"
            md="2"
            class="d-flex justify-content-center align-items-center"
          >
            <b-button
              @click="clearFilter"
              variant="outline-primary"
              class="btn-icon mt-2"
            >
              <feather-icon icon="RefreshCcwIcon" /> Filtreyi Sıfırla
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        hover
        ref="refUserListTable"
        class="text-left position-relative"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        :items="customerAppoinmetData"
        responsive
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No Matching Creates Found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(status)="data">
          <b-badge v-if="data.item.Status == 'Active'" variant="success">
            {{ $t(data.item.Status) }}
          </b-badge>
          <b-badge v-if="data.item.Status == 'Canceled'" variant="danger">
            {{ $t(data.item.Status) }}
          </b-badge>
          <b-badge v-if="data.item.Status == 'Used'" variant="warning">
            {{ $t(data.item.Status) }}
          </b-badge>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm=""
            class="d-flex align-items-right justify-content-right radius 0"
          >
            <small v-if="$i18n.locale === 'tr'" class="mt-1">
              içerisindeki {{ totalRows }} kayıttan {{ currentPage * 1 }}-{{
                currentPage * perPage
              }}
              arası ({{ currentPage }}.sayfa) gösteriliyor
            </small>
            <small v-if="$i18n.locale === 'en'" class="mt-1">
              showing {{ currentPage * 1 }}-{{ currentPage * perPage }} (page
              {{ currentPage }}) of {{ totalRows }} records</small
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="6"
            sm=""
            class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius
              0
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aling="right"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 r-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DataBase from "@/services/DataBase.js";
import i18n from "@/libs/i18n";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "activityList",
  components: {
    BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      language: i18n.locale,
      totalRows: null,
      bgcolor: "primary",
      searchQuery: "",
      currentPage: 1, //
      total_item: 1,

      isSortDirDesc: true,
      sortBy: "id",
      search_query: "",
      perPage: 25,
      locale: this.$i18n.locale,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        {
          key: "PersonId",
          label: this.$t("PERSON ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "TrainerId",
          label: this.$t("TRAINER ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "TrainerProductId",
          label: this.$t("TRAINER PRODUCT ID"),
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "ProductName",
          label: this.$t("ÜRÜN ADI"),
          tdClass: "minColumnWidth",
        },
        {
          key: "Identity",
          label: this.$t("KİMLİK NO"),
        },
        {
          key: "CustomerName",
          label: this.$t("MÜŞTERİ ADI"),
          sortable: true,
          tdClass: "minColumnWidth",
        },
        {
          key: "PhoneNumber",
          label: this.$t("TELEFON"),
          sortable: true,
        },
        {
          key: "Email",
          label: this.$t("EMAİL"),
          sortable: true,
        },
        {
          key: "Date",
          label: this.$t("TARİH"),
          sortable: true,
        },
        {
          key: "StartTime",
          label: this.$t("START"),
          sortable: false,
        },
        {
          key: "EndTime",
          label: this.$t("END"),
          sortable: false,
        },
        {
          key: "TrainerName",
          label: this.$t("EĞİTMEN ADI"),
          sortable: true,
          tdClass: "minColumnWidth",
        },
        {
          key: "Status",
          label: this.$t("STATUS"),
        },
      ],
      customerAppoinmetBaseData: [],
      customerAppoinmetData: [
        //customerAppoinmet:this.$models.customerAppointmentDto(),
      ],

      selected: "tüm",
      options: [
        {
          text: this.$t("All Activities"),
          value: "tüm",
        },
        {
          text: this.$t("Active Activites"),
          value: "aktif",
        },
        {
          text: this.$t("Deactive Activites"),
          value: "deaktif",
        },
      ],
      companyBranchId: "",

      start_date: "12.12.2021",
      end_date: "12.12.2021",

      configStart: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        minDate: "01.01.1940",
        maxDate: "today",
      },

      configEnd: {
        dateFormat: "d.m.Y",
        locale: Turkish,
        maxDate: "31.12.2025",
        minDate: "01.01.1940",
      },
      filter: null,
      filterOn: [],
      checkActive: false,
    };
  },
  watch: {
    customerAppoinmetData(val) {
      this.totalRows = val.length;
    },

    activityData(val) {
      this.total_item = val.length;
    },
    start_date(val) {
      // this.customerAppoinmetData = []
      this.configEnd.minDate = val; //bitiş tarihininin başlangıç değeri en az başlangıç tarihi değerine eşit olabilir
      // this.customerAppoinmetBaseData.map(el => {
      //   console.log('el date',el.Date)
      //   if (el.Date.split(' ')[0] >= val && el.Date.split(' ')[0] <= this.end_date) {
      //     this.customerAppoinmetData.push(el)
      //   }
      // })

      this.getCustomerAppointmentList(
        this.companyBranchId,
        this.start_date,
        this.end_date
      );
    },
    end_date(val) {
      //  this.customerAppoinmetData = []
      this.configStart.maxDate = val; //başlangıç tarihinin son değeri en çok bitiş tarihi değerine eşit olabilir
      // this.customerAppoinmetBaseData.map(el => {
      //   if (el.Date.split(' ')[0] <= val && el.Date.split(' ')[0] >= this.start_date) {
      //     this.customerAppoinmetData.push(el)
      //   }
      // })

      this.getCustomerAppointmentList(
        this.companyBranchId,
        this.start_date,
        this.end_date
      );
    },
    checkActive(val) {
      if (val.toString() == "checked") {
        let tempAppointmentData = [];
        this.customerAppoinmetData.map((x) => {
          console.log(x);
          if (x.Status == "Active") {
            tempAppointmentData.push(x);
          }
        });
        this.customerAppoinmetData = tempAppointmentData;
      } else {
        this.customerAppoinmetData = this.customerAppoinmetBaseData;
      }
    },
  },
  methods: {
    clearFilter() {
      this.start_date = null;
      this.end_date = null;
      this.checkActive = false;
      this.customerAppoinmetBaseData = null;
      this.customerAppoinmetData = this.customerAppoinmetBaseData;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    //eğitmenlerin aktiflik, deaktiflik durumlarına göre filtrelenebilmesi
    changeFiltering(arg) {
      if (arg === "tüm") {
        this.activityData = this.activityBaseData;
      } else if (arg === "aktif") {
        //aktif seçilirse base veriler içinde map et aktif true olanları tablo verisi olarak al
        let data = [];
        this.activityBaseData.map((x) => {
          if (x.is_active === true) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.activityData = data;
      } else if (arg === "deaktif") {
        //deaktif seçilirse base veriler içinde map et aktif false olanları tablo verisi olarak al
        let data = [];
        this.activityBaseData.map((x) => {
          if (x.is_active === false) {
            data.push(x);
            this.total_items = data.length;
            this.currentPage = 1;
          }
        });
        this.activityData = data;
      } else {
        //null gelebilir..
        this.activityData = this.activityBaseData;
      }
    },
    setStatus(_endDate, _isActive) {
      if (_isActive == false || _isActive == 0) {
        return "Canceled";
      } else {
        if (new Date(_endDate) < new Date()) {
          return "Used";
        } else {
          return "Active";
        }
      }
    },
    getCustomerAppointmentList(companyBranchId, StartDate, EndDate) {
      var prmStartDate =
        StartDate !== null &&
        this.$functions.ConvertToEnglishDateFormat(StartDate);

      var prmEndDate =
        EndDate !== null && this.$functions.ConvertToEnglishDateFormat(EndDate);

      DataBase.StudioService.get_customer_appointment_list(
        companyBranchId,
        prmStartDate,
        prmEndDate
      ).then((res) => {
        if (!res.is_success) {
          return;
        }
        this.customerAppoinmetBaseData = res.result.map((x) => {
          return this.$models.customerAppointmentDto(
            x.person_id,
            x.trainer_id,
            x.trainer_product_id,
            x.product_name,
            x.identification_number,
            x.customer_name,
            x.phone_number,
            x.email,
            this.moment(x.start_date)
              .locale(this.language)
              .format("L"),
            this.moment(x.start_date).format("HH:mm"),
            this.moment(x.end_date)
              .add(1, "minute")
              .format("HH:mm"),
            x.trainer_name,
            this.setStatus(x.end_date, x.is_active) // active, deactive, expired
          );
        });
        this.customerAppoinmetData = this.customerAppoinmetBaseData;
      });
    },
    changeColor(value) {
      if (value == "GX") {
        this.bgcolor = "primary";
      } else if (value == "PT") {
        this.bgcolor == "success";
      } else {
        this.bgcolor == "danger";
      }
    },
    activityActiveChange(activity_id, is_active) {
      is_active = is_active == 0 ? "1" : is_active == "1" ? "0" : "1";

      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want To Change Activity Status?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          DataBase.StudioService.activity_isactive_change(
            activity_id,
            is_active
          ).then((res) => {
            if (!res.is_success) {
              this.$functions.Messages.error("Action failed!");
              return;
            }
            this.$functions.Messages.success("Activity status changed");
            // this.getActivityData();
          });
        }
      });
    },
  },
  created() {
    this.companyBranchId = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id;
    // this.start_date = null
    // this.end_date = null

    this.start_date = this.moment()
      .add(-1, "month")
      .locale("tr")
      .format("L");

    this.end_date = this.moment()
      .add(1, "month")
      .locale("tr")
      .format("L");

    this.getCustomerAppointmentList(
      this.companyBranchId,
      this.start_date,
      this.end_date
    );
    //this.getActivityData();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/activityList.scss" scoped></style>
<style>
.minColumnWidth {
  min-width: 250px !important;
}
</style>
